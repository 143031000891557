import * as React from "react";
import { Container } from "react-bootstrap";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { quantPageTplData } from "../data/page-tpl-quant.data";
import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import Layout from "../components/Layout";
import Section from "../components/Section";

import Hero from "../componentsSections/Hero";
import OurNumbers from "../componentsSections/OurNumbers";
import TabsSection from "../components/TabsSection";
import ImageSection from "../components/ImageSection";
import Features from "../components/Features";
import GetInTouchButton from "../components/GetInTouchButton";
import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";

const PageQuant = ({ pageContext: { id } }) => {
  const seoPageData = getCurrentPageSeo(id);
  const pageData = quantPageTplData(id);

  return (
    <>
      <Layout navbarVariant={pageData.hero.image ? "transparent" : undefined}>
        {/* SEO Metas */}
        <Seo post={seoPageData} />

        {/* Page head */}
        <Hero {...pageData.hero} variant={3} />

        {/* Our Numbers Section */}
        <Section>
          <OurNumbers
            containerClassName="-top-spacer py-0"
            {...pageData.ourNumbers}
          />
        </Section>

        {/* Quant Trading Lifecycle Section */}
        <Section>
          <TabsSection {...pageData.quantTradingLifestyle} />
        </Section>

        <Section>
          <GetInTouchButton />
        </Section>

        {/* Product Mockup Image Section */}
        <Section className="-mobile-full">
          <ImageSection {...pageData.productMockupImage} />
        </Section>

        <Section>
          <GetInTouchButton />
        </Section>

        {/* Features Section */}
        <Section className="-mobile-full">
          <Features {...pageData.features} />
        </Section>

        {/* GetInTouch Section */}
        <Section className="-mobile-full">
          <GetInTouch />
        </Section>

        {/* News and insights Section */}
        <Section>
          <NewsAndInsights />
        </Section>
      </Layout>
    </>
  );
};

export default PageQuant;
