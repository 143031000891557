// Default imports
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import GenericWideSection from "../components/GenericWideSection";
import WpImageFluid from "../components/WpImageFluid";

const OurNumbers = ({ items, image, ...props }) => {
  return (
    <GenericWideSection vertical contentClassName="py-0" {...props}>
      <Row className="our-numbers">
        <Col className="my-auto" md="12" lg="15">
          <Row>
            {items.map(({ number, text }, index) => (
              <Col
                key={index}
                className="our-numbers__item"
                xs="24"
                lg={{ span: "6", offset: index > 0 ? "3" : "0" }}
              >
                <p className="our-numbers__number">{number}</p>
                <p className="our-numbers__text">{text}</p>
              </Col>
            ))}
          </Row>
        </Col>
        <Col
          className="our-numbers__image-container"
          xs="24"
          md={{ span: "11", offset: "1" }}
          lg={{ span: "7", offset: "2" }}
        >
          <WpImageFluid className={`our-numbers__image `} image={image} />
        </Col>
      </Row>
    </GenericWideSection>
  );
};

export default OurNumbers;
